import Vue from 'vue';
import axios from 'axios'
import store from '../store'
import Router from '../router/index'

// Full config:  https://github.com/axios/axios#request-config
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

let config = {
  baseURL: process.env.NODE_ENV == 'development'? '/api': '',
  timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

function getQueryString(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const urlObj=window.location;
  var r =urlObj.href.indexOf('#')>-1? urlObj.hash.split("?")[1].match(reg) : urlObj.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}

_axios.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    config.headers.r = new Date().getTime();
    config.headers['accessToken'] = store.state.accessToken
    config.headers['source'] = 'H5'
    let channel = getQueryString('channel') || localStorage['channel']
    if (channel) {
      localStorage['channel'] = channel
      config.headers['channel'] = channel
    }
    config.headers['merchantId'] = 'b5a84f164746cfd448b144f543e22808'
    config.headers['inputChannel'] = 'LOANNAIRA'
    config.headers['versionId'] = '20210618'
    config.headers['r'] = new Date().getTime()
    return config;
  },
  function(error) {
    // 处理请求错误
    return Promise.reject(error);
  }
);

// 添加响应拦截器
_axios.interceptors.response.use(
  function(response) {
    // 登录过期
    if (response.data && response.data.status.code == '997') {
      Router.push('/user/login');
      localStorage.removeItem('vuex');
    }
    // 处理响应数据
    return Promise.resolve(response.data);
  },
  function(error) {
    let data = error.data? error.data: (error.request.response && JSON.parse(error.request.response));
    // 登录过期
    if (data && data.status.code == '997') {
      Router.push('/user/login');
      localStorage.removeItem('vuex');
    }
    // 处理响应错误
    return Promise.reject(data);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;