export default {
  welcome: 'Welcome',
  projectName: 'LoanNaira',
  login: 'log in',
  register: 'registered',
  phone: 'phone number',
  mine: 'mine',
  logOut: 'sign out',
  loan: 'Loan',
  profile: 'Profile',
  account: 'Account',
  loginTips1: 'Click to use SMS verification code to log in quickly',
  loginTips2: 'Click to use password to log in quickly',
  upTo: 'UP TO',
  loanInfo: 'Loan arrived so fast ! !',
  userRepayInfo: "How much should I pay back?",
  userRenewalInfo: "How much did I borrow last time?",
  userBorrowInfo: "How much can I borrow?",
  firstName: 'first name',
  firstNamePlaceholder: 'Please enter the name',
  middleName: 'Middle name (optional)',
  middleNamePlaceholder: 'Please enter middle name',
  lastName: 'Last name',
  lastNamePlaceholder: 'Please enter last name',
  backupPhone: 'Backup phone (optional)',
  backupPhonePlaceholder: 'Please enter a backup phone',
  whatsApp: 'whatsApp',
  whatsAppPlaceholder: 'Please enter whatsApp',
  whatsAppError: 'whatsApp cannot be empty',
  facebook: 'Facebook(optional)',
  facebookPlaceholder: 'Please enter Facebook',
  viewMore: 'More information',
  userRecomendation: 'customer feedback',
  personalInfo: 'Personal information',
  workInfo: 'work messages',
  jobauth: 'Work certification',
  wontactInfo: 'contact information',
  relationshipauth: 'Contact authentication',
  idcardInfo: 'ID card information',
  idcardauth: 'Authentication',
  confirmOrder: 'Confirm Order',
  bankCardInfo: 'Bank card information',
  birthday: 'birthday',
  birthdayError: 'Birthday cannot be empty',
  birthdayPlaceholder: 'Please choose your birthday',
  email: 'mailbox',
  emailPlaceholder: 'Please enter your email',
  emailError: 'E-mail can not be empty',
  gender: 'gender',
  genderError: 'Please select gender',
  repaymentDate: 'Repayment date',
  withdrawalAmount: 'Available cash amount',
  repayableAmount: 'Amount due',
  loanDetail: 'order details',
  copy: 'Copy',
  year: 'Year',
  month: 'Month',
  date: 'Date',
  copySuccess: 'Copy successfully!',
  invitationAddress: 'Exclusive invitation address',
  inviteFriendsStep: 'Get rewards in three easy steps',
  inviteFriendsSteptips1: 'Invite friends to register',
  inviteFriendsSteptips2: 'Friend loan successful',
  inviteFriendsSteptips3: 'Get cash coupons',
  activityRules: 'Activity Rules',
  toBeExchanged: 'To be redeemed',
  exchanged: 'Redeemed',
  expired: 'expired',
  validUntil: 'Valid until',
  exchangeImmediately: 'Redeem now',
  couponPopupTitle: 'Tips',
  couponPopupTips: 'Are you sure to redeem the coupon now?',
  activityRulesList: [
    '1. Both new and old users can participate in this event.',
    '2. Event bonuses are limited, first-come first-served, while supplies last.',
    '3. Successfully invite a friend to get rewards. (You can get rewards only after your friends complete the loan).',
    '4. The reward amount will be issued directly to your account.',
    '5. You can check the reward income in "coupons".',
    '6. The final analysis right of this activity belongs to the company.',
  ],
  male: 'male',
  female: 'Female',
  degree: 'Education',
  degreePlaceholder: 'Please select your education',
  degreeError: 'Education degree cannot be empty',
  marriage: 'Marriage',
  marriagePlaceholder: 'Please select marital status',
  marriageError: 'Marital status cannot be empty',
  religion: 'religion',
  religionPlaceholder: 'Please choose religion',
  religionError: 'Religion cannot be empty',
  addressSelect1: 'Province/City',
  addressSelect2: 'Area',
  addressPlaceholder: "Please select address",
  companyAddressSelectPlaceholder: "Please select company address",
  confirmBankCardInfo: 'Confirm bank card information',
  addressError: 'Residential address cannot be empty',
  houseNo: 'House number',
  houseNoPlaceholder: "Please enter the house number",
  houseNoError: 'Please enter the house number',
  companyAddress: 'company address',
  companyAddressPlaceholder: "Please enter company address",
  companyAddressError: "Company address cannot be empty",
  residentialAddress: 'Residential Address',
  salaryType: 'Salary type',
  salaryTypePlaceholder: 'Please select salary type',
  salaryTypeError: 'Please select salary type',
  companyName: 'company name',
  payDate: 'Pay cycle',
  payDatePlaceholder: 'Please select pay period',
  payDateError: 'Please select pay period',
  payDateTips: 'Example:25 if you’re paid on the 25th',
  companyNamePlaceholder: "Please enter company name",
  companyNameError: "Company name cannot be empty",
  averageMonthlyIncome: 'Average monthly income',
  averageMonthlyIncomePlaceholder: "Please select average monthly income",
  averageMonthlyIncomeError: 'Average monthly income cannot be empty',
  cashRedPacket: 'Cash red envelope',
  invitePrize: 'Invitation with prize',
  inviteFriendsImmediately: 'Invite friends now',
  noInviteFriends: 'Haven\'t invited friends yet',
  myPromotionTips: 'Time to become a new user:',
  workPhone: 'Work phone (optional)',
  bankInfo: 'Bank card',
  bankInfoTips: 'Reminder: Collection and repayment may be affected by banking business',
  bankInfoPopupTips: 'If the account information is incorrect, you will not be able to get a loan.',
  workPhonePlaceholder: 'Please enter work phone',
  workPhoneError: 'Work phone cannot be empty',
  workPhoneWarning: 'Please enter the correct work phone',
  relativeContact: 'Relative Contact',
  otherContact: 'Other contacts',
  relationship: 'relationship',
  relationshipPlaceholder: 'Please select the contact relationship',
  relationshipError: 'Relationship cannot be empty',
  orderHistory: 'Order history',
  repaymentInfo: 'Repayment information',
  repayment: 'Repayment',
  remainingAmountDue: "Remaining amount due",
  repaymentTips: "There is one unpaid bill.",
  repaymentMessage: "We suggest that you repay the loan immediately on this page, so as not to affect your next loan application.",
  repaymentMessage2: "We need a few minutes to process your loan repayment application，please wait patiently. If you cannot receive the verification code, please resend the verification code. Thanks for your cooperation.",
  inviteFriends: 'invite friends',
  discountCoupon: 'voucher',
  myPromotion: 'My promotion',
  phonePlaceholder: 'Please enter your mobile number',
  phoneWarning: 'please enter a valid phone number',
  phoneError: 'Phone number cannot be empty',
  password: 'password',
  passwordPlaceholder: 'Please enter the password',
  passwordWarning: 'Password format is wrong',
  passwordError: 'password can not be blank',
  forgetPassword: 'Forgot password',
  validateCode: 'Verification code',
  smsCode: 'Verification code',
  youCanTry: 'You can also try',
  getIvrCode: 'Get voice verification code',
  confirmPassword: 'confirm password',
  validateCodeWarning: 'Verification code format error',
  validateCodeError: 'verification code must be filled',
  comfirmPasswordWarning: 'Two password entries are inconsistent',
  comfirmPasswordError: 'Please enter the password',
  getSmsTips: 'The verification code may take a long time, please be patient',
  identifyCardTips: 'Photo should be clear and complete',
  holdTheIdentifyCardTips: 'I.e Take a picture holding your card. Fully exposed NIK card information Avioid exposed ID overlaps width the NIK card clear.',
  employeeIdCardTips: 'Upload your Emploe ID card to get more borowing money and loan faster.',
  loanTips: "Maintaining a good repayment behavior will help you to increase your loan amount. 96% of users loan amount haveincreased subsequently.",
  orderConfirmation: "Order confirmation:",
  fee: 'Fee',
  amountPayable: "Amount payable",
  repaymentReliefReward: "Repayment relief reward",
  repaymentAmount: "Repayment loan amount",
  discountTitle: 'coupon',
  discountTips: 'You have a coupon to use',
  overdue: 'Expired',
  useImmediately: 'use immediately',
  stage: "Stage",
  free: 'Free',
  clickingAgreeOur: "By clicking Submit,you agree to our",
  loanAgreement: "Loan agreement",
  clickModifyBankCard: "Click here to modify bank card",
  modifyBankInfo: 'Modify bank card',
  oldBankAccount: "Old Bank Account",
  newBankAccount: "New Bank Account",
  getCodeTitle: "Please typ the verification code sent to",
  codeInfo: "Cant't receive the verification code?You can also check your Mobile phone signal or try the following ways.",
  getIvrHint: "Receive code by call",
  getCodeHint: "Re-acquire verification code",
  deductionAuth: "ATM Bank Card",
  cardVerificationStatus: "Card Verification Status",
  deductionAuthTips: "Please verify your bank card to get more credit.In this process,system will deduct your 50 NR for verification.It will be return to your when repay loan.",
  notVerified: "NOT VERIFIED",
  verified: "VERIFIED",
  validateCard: "Validate Card",
  stepBack: "Step back",
  applicationRepayment: "This application for repayment",
  confirm: 'Confirm',
  cancel: 'Cancel',
  idcard: 'BVN',
  idcardPlaceholder: 'Please enter BVN',
  idcardError: 'BVN cannot be empty',
  confirmModification: 'Confirm the changes',
  modifyImmediately: 'Edit now',
  modify: 'modify',
  submit: 'submit',
  verifyMarkTips: 'Name does not match bank account number',
  settleMsg1: '*The reapplication quota of closed customers is higher and the review is faster.！',
  overdueMsg1: '*Your loan is overdue, the overdue loan will affect your credit history, and you will not be able to borrow from other institutions in the future. Please repay the loan as soon as possible.！',
  waitTips1: 'Loan information (under review)',
  waitTips2: 'Refresh the interface to view loan status',
  supplementaryTips1: 'Application failed',
  supplementaryTips2: 'Your identity information is incorrect',
  supplementaryTips3: 'Please complete the supplement within one working day, otherwise the application will be cancelled!',
  passedTips1: 'Application approved (in lending)',
  toBeRepaid1: 'To be repaid',
  toBeRepaid2: 'Prepayment helps to increase the amount and term of your next loan.',
  settled1: 'Settled',
  settled2: 'Congratulations on your chance to get a higher amount of loan. Go and apply!',
  overdue1: 'Overdue',
  overdue2: 'In order to avoid penalty interest, we suggest that you pay in advance or on time. Maintaining a good reputation will qualify you for a higher loan line through LoanNaira.',
  rejectedTips1: 'Application failed',
  rejectedTips2: 'This application failed',
  dueDate: 'Due date',
  amountDue: 'Amount due',
  loanDate: 'Borrowing date',
  loanAmount: 'Loan amount',
  loanTerm: 'Loan period',
  repaymentPeriod: 'Repayment period',
  applicationStatus: 'application status',
  moneyMaxTips: 'Your current maximum loan amount',
  daysMaxTips: 'Your current maximum loan period',
  filingDate: 'Submission date',
  paymentDate: 'Payment date',
  serviceFee: "Service charge",
  amountOfRepayment: "Amount of repayment",
  day: 'Day',
  waitMsg1: 'Your order. Your loan application is under review. The staff will contact you within 1-2 working days. Please keep the phone open!',
  passedMsg1: '*Your loan application has been reviewed and the loan is being released. It is expected that it will arrive in 1-2 working days;',
  passedMsg2: 'If you have not received the payment, please check with the bank where your bank card is located. For other questions, please contact customer service',
  reapply: 'Re-apply',
  back: 'back',
  nextStep: 'Next step',
  applyImmediately: 'apply immediately',
  clickApplyAgree: 'Click apply to agree',
  serviceAgreement: 'Service Agreement',
  telephone: 'customer hotline',
  supplementaryInformation: 'additional materials',
  supplementaryImage: 'Supplementary documents',
  supplementaryBankCard: 'Supplementary bank card',
  prompt: 'prompt',
  idcardExample: 'Example of uploading ID photo',
  identifyCard: 'Identify card(optional)',
  holdTheIdentifyCard: 'Hold the identify card(optional)',
  employeeIdCard: 'Employee ID card(optional)',
  voterCard: 'Voter card(optional)',
  driverLicense: 'Driver license(optional)',
  passport: 'Passport(optional)',
  taxCard: 'Tax card(optional)',
  name: 'Name',
  namePlaceholder: 'Please type in your name',
  nameError: 'Name cannot be empty',
  bankAccountHolder: 'Bank card holder',
  atmBankCardNo: 'atm bank card no',
  atmBankCardNoPlaceholder: 'Please enter atm bank card no',
  atmBankCardNoError: 'Please enter atm bank card no',
  bankCardNumber: 'Bank Account no',
  bankCardNumberPlaceholder: 'Please enter the Bank Account no',
  bankCardNumberError: 'Bank Account no cannot be empty',
  bankAccountNumber: 'Bank Account',
  bankAccountNumberPlaceholder: 'Please enter bank account number',
  bankAccountNumberError: 'Bank account cannot be empty',
  bank: 'bank',
  bankPlaceholder: 'Please select a bank',
  bankError: 'Bank cannot be empty',
  bankCard: 'Bank card',
  contractNo: 'Contract No',
  amount: 'Amount',
  amountPlaceholder: 'Please enter the amount',
  notHaveLoan: "You do not have a loan currently,please get a loan first.",
  getMoneyNow: "Get money now",
  selectPhoneTips: "Please select a mobile phone number you have used",
  applicationDate: 'Date of Application',
  loanLimit: 'Repayment period',
  contractStatus: 'Contract status',
  profileTips: 'We promise to protect your privacy information.',
  profileCreateInfo1: 'We are calculating your quota',
  profileCreateInfo2: 'Expected to be completed in 10 seconds,please be patient.',
  loanTotalAmt: 'Total amount payable',
  realTotalAmt: 'Total amount repaid',
  noBankCard: 'No bank card',
  addBankCard: 'Add bank card',
  deductionSequence: 'Deduction sequence',
  checkTheBill: 'Check the bill',
  unbind: 'unbind',
  deductionSequenceTips: 'Deductions will be made in the following order',
  longPressDrag: 'Long press drag',
  warmTips: 'Warm tips',
  unbindTips: 'Are you sure you want to unbind now?',
  cardBills: 'Card bills',
  refund: 'Refund',
  status: 'Status',
  refundTips: 'Refund application is successful, refund may be delayed, please wait patiently',
  repaymentRecord: 'Repayment record',
  processing: 'Processing',
  success: 'Success',
  failure: 'failure',
  idcardExampleList: [
    'standard', 'Missing border', 'Blurred focus', 'Photocopy'
  ],
  byPaymentCompany: 'By payment company',
  byBank: 'By bank',
  accountName: 'Account name',
  bankName: 'Bank Name',
  bankAccount: 'Bank account',
  repaymentRemark: 'Repayment remark',
}