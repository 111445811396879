<template>
  <div class="home">
    <mt-header :title="$t('projectName')">
      <call-phone slot="right" />
      <!-- <i slot="right" class="iconfont titieIcon" @click="$router.push('/mine')">&#xe677;</i> -->
    </mt-header>
    <div class="loan">
      <div class="box">
        <img class="bg" src="../assets/loan_img_1.png">
        <div class="val">
          <div class="title">
            {{ title }}
          </div>
          <div class="tips" v-if="loanType === 1">
            ₦ {{ user.currRepayAmt | formatMoney }}
          </div>
          <div class="item" v-if ="user.applyStatus === 'OVERDUE' || user.applyStatus === 'NORMAL'">
            <span class="item_lable">Repayment date:</span><span class="item_value1">{{ user.repayDate || '????' }}</span>
          </div>
          <div class="item" v-if ="user.applyStatus === 'OVERDUE'">
            <span class="item_lable">Over due</span><span class="item_value2">{{ user.overdueDays || '????' }} Days</span>
          </div>
          <div class="tips" v-else>
            ₦ {{ user.maxViewAmount | formatMoney }}
          </div>
          <div class="bottom">
            <mt-button size="large" @click="submit">{{
                submitTitle
            }}</mt-button>
          </div>
        </div>
      </div>
      <div class="link"  v-if="loanType === 0">
        {{ $t('clickApplyAgree') }}
        <span @click="onServiceAgreement">{{ $t('serviceAgreement') }}</span>
      </div>
    </div>
    <div class="flag">
      <div class="val">
        <div>
          {{ $t('upTo') }}
          <span>₦ 600,000</span>
        </div>
        <div class="info">{{ $t('loanInfo') }}</div>
        <div class="go" @click="submit">GO >></div>
      </div>
      <img src="../assets/loan_img_3.png">
    </div>
    <!-- <div class="userRecomendation">
      {{ $t('userRecomendation') }}
      <svg aria-hidden="true" class="icon">
        <use xlink:href="#iconkuaisu_jiasu" />
      </svg>
    </div>
    <div class="inviteFriends">
      <div class="val">
        <div>{{ $t('inviteFriends') }}</div>
        <div class="btn" @click="$router.push('/mine/inviteFriends')">
          {{ $t('viewMore') }}
        </div>
      </div>
      <img src="../assets/loan_img_2.png">
    </div> -->
    <my-tabbar />
  </div>
</template>

<script>
import qs from "qs";
import { mapState, mapMutations, mapActions } from "vuex";
import { getUserInfo, contractDetail } from '../utils/api'
import callPhone from '@/components/callPhone.vue'
import myTabbar from '@/components/myTabbar.vue'
import { step } from '../utils/api'
import getLocation from '../utils/gps'
import branch from '../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../utils/gtm.js'
export default {
  name: 'Home',
  components: {
    callPhone,
    myTabbar
  },
  data() {
    return {
      loanType: 0,
      item: {},
      submitTitle: ''
    }
  },
  computed: {
    ...mapState(["brand", "user"]),
    title() {
      if (this.loanType === 1) {
        return 'Repayment amount'
      } else {
        return "Max available amount"
      }
    },
  },
  watch: {
    'user.applyStatus': {
      handler(val) {
        switch (val) {
          case 'EMPTY': // 无申请单
          case 'APPLY': // 申请中
            this.loanType = 0;
            this.submitTitle = 'Apply'
            return;
          case 'SIGN':
          case 'APPROVE':
          case 'LOAN':
          case 'WAIT':
            this.loanType = 0;
            this.submitTitle = 'View Detail'
            return;
          case 'REPAY':
          case 'NORMAL':
          case 'OVERDUE':
            this.loanType = 1;
            this.submitTitle = 'Repayment'
            return;
          default:
            this.loanType = 2;
            this.submitTitle = 'Apply'
            break;
        }
      },
      immediate: true,
    }
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P00_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P00_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await Promise.all([
        this.getUser(),
      ])
      if (this.user.applyId) {
        await this.getContractDetail()
      }
      this.$indicator.close();
    },
    submit() {
      switch (this.user.applyStatus) {
        case 'EMPTY':
        case 'APPLY':
        case 'REJECTED':
        case 'CANCEL':
        case 'SETTLE':
        case 'FREEZE':
        case 'EXPIRED':
          if (this.user.continuedLoan === 'Y') {
            this.onReApply() // 续贷申请
            break
          }
          this.onApplyPre() // 首贷申请
          break
        case 'WAIT':
        case 'LOAN':
          this.$router.push({path: '/apply/done'})  // 订单详情
          break
        case 'NORMAL':
        case 'OVERDUE':
          this.$router.push(`/apply/repayment?contractNo=${this.user.applyId}`)
          break
        case 'SUPPLEMENTARY':
        case 'SUPPLEMENT_IMAGE': // 补证件
        case 'SUPPLEMENT_BANK_CARD': // 补银行卡
        case 'SUPPLEMENT_IMAGE_CARD': // 补证件 & 银行卡
          this.$router.push({path: '/step/supplementary'}) // 补充资料
          break
        default:
          this.$router.push({path: '/apply/done'})  // 订单详情
          break
      }
    },
    // 预申请
    async onApplyPre() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('01_FIRST_LOAN_APPLY', { content_name: '01_FIRST_LOAN_APPLY' })
        }
        // Google Analytics  FIRST_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'FIRST_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }
      await this.$axios({
        method: "post",
        url: step,
        data: {
          deviceId: 'H5',
          phone: this.user.phone,
          idcard: this.user.idcard,
          gps: await getLocation(),
          currentStep: 1,
          totalSteps: 7,
        },
      })
        .then(async (e) => {
          if (e.status.code === "000") {
            this.SET_USER({
              ...this.user,
              name: e.body.name,
              applyId: e.body.applyId
            })
            this.$router.push('/step/realnameauth')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('01_FIRST_LOAN_APPLY', { metadata: this.user.phone })
    },
    // 续贷申请
    async onReApply() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('11_CONTINUED_LOAN_APPLY', {
            content_name: '11_CONTINUED_LOAN_APPLY'
          })
        }
        // Google Analytics  CONTINUED_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'CONTINUED_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }
      await this.$axios({
        method: "post",
        url: step,
        data: {
          deviceId: 'H5',
          phone: this.user.phone,
          idcard: this.user.idcard,
          gps: await getLocation(),
          currentStep: 1,
          totalSteps: 7,
          continuedLoan: 'Y'
        }
      })
        .then(async (e) => {
          if (e.status.code === "000") {
            this.SET_USER({
              ...this.user,
              name: e.body.name,
              applyId: e.body.applyId
            })
            this.$router.push('/step/confirm')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('11_CONTINUED_LOAN_APPLY', { metadata: this.user.phone })
    },
    onServiceAgreement() {
      if (this.brand.channelInfo) {
        window.open(this.brand.channelInfo.privacyUrl)
      }
    },
    async getContractDetail() {
      await this.$axios({
        method: 'post',
        url: contractDetail,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: qs.stringify({
          applyId: this.user.applyId,
        }),
      })
        .then((e) => {
          if (e.status.code === '000') {
            this.item = e.body
          }
        })
        .catch(() => {})
    },
    async getUser() {
      await this.$axios({
        method: "post",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code === "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
  }
}
</script>

<style scoped lang="scss">
.home {
  .titieIcon {
    color: #47B526;
    font-size: 26px;
  }
  /deep/ .mint-header-title {
    font-size: 26px;
    font-weight: bold;
    color: #47B526;
    text-shadow: 0px 3px 3px rgba(34, 35, 40, 0.11);
  }
  .loan {
    margin: 15px;
    box-shadow: 0px 0px 10px 0px rgba(34, 35, 40, 0.11);
    border-radius: 8px;
    .box {
      position: relative;
      overflow: hidden;
      .val {
        // padding-top: 20px;
        position: absolute;
        width: 100%;
        height: 100%;
        .title {
          font-weight: bold;
          color: #47B526;
          font-size: 15px;
          text-align: center;
          margin-top: 25px;
        }
        .tips {
          margin-top: 5px;
          font-size: 20px;
          text-align: center;
          font-weight: bold;
          color: #47B526;
        }
        .item{
          margin-top: 10px;
          text-align: center;
          color: #47B526;
          font-weight: bold;
        }
        .item_value1{
          display: inline-block;
          width: 90px;
          height: 20px;
          line-height: 20px;
          font-weight: 800;
          font-size: 14px;
          color: #232A2A;
        }
        .item_value2{
          display: inline-block;
          margin-left: 10px;
          width: 90px;
          height: 20px;
          border-radius: 14px;
          border: 1px solid #EE0B0B;
          line-height: 20px;
          font-weight: 500;
          font-size: 14px;
          color: #EE0B0B;
        }
      }
    }
    .bg {
      width: 100%;
      float: left;
    }
    .bottom {
      position: absolute;
      bottom: 8px;
      left: 50px;
      right: 50px;
      .mint-button {
        color: #A07120;
        height: 33px;
        font-size: 13px;
        background-color: #FCFF01;
        border: 1px solid #CDCF1D;
        border-radius: 33px;
      }
    }
    .link {
      padding: 10px 0;
      text-align: center;
      font-size: 14px;
      color: black;
      padding: 10px 0;
      span {
        color: black;
        text-decoration: underline;
      }
    }
  }
  .flag {
    position: relative;
    margin: 20px 15px 15px 15px;
    background-color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px 0px rgba(34, 35, 40, 0.11);
    img {
      position: absolute;
      width: 100%;
      z-index: 0;
    }
    .val {
      position: relative;
      z-index: 1;
      padding: 10px 0px;
      margin-left: 9px;
      flex: 1;
      font-size: 20px;
      color: #47B526;
      font-weight: 700;
      div > span {
        color: #FFD801;
      }
      .info {
        color: #869096;
        font-size: 13px;
        margin: 5px 0px;
        margin-left: 10px;
      }
      .go {
        font-size: 14px;
        background-color: #47B526;
        border-radius: 14px;
        color: white;
        display: inline-block;
        padding: 5px 15px;
        margin-left: 40px;
      }
    }
  }
  .userRecomendation {
    padding: 10px 25px 10px 25px;
    font-size: 15px;
    font-weight: bold;
  }
  .inviteFriends {
    position: relative;
    margin: 0px 15px 20px 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9b6ef;
    .val {
      position: relative;
      z-index: 1;
      flex: 1;
      text-align: center;
      div {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #373032;
      }
      .btn {
        margin-top: 10px;
        padding: 5px 20px;
        margin-bottom: 15px;
        border-radius: 4px;
        color: white;
        background-color: #47B526;
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
      }
    }
    img {
      position: absolute;
      width: 100%;
      top: 0;
    }
  }
}
</style>
