<template>
  <div>
    <img src="../assets/hotline.png" class="icon" @click="onCall">
    <mt-popup v-model="popupVisible">
      <div class="box">
        <mt-cell v-for="(item, index) in ccphones" :key="index" :title="item" is-link @click.native="callPhone(item)"></mt-cell>
        <div class="bottom">
          <mt-button type="primary" size="large" @click="popupVisible = false">{{
            $t("cancel")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data () {
    return {
      popupVisible: false,
    }
  },
  computed: {
    ...mapState(['brand']),
    ccphones() {
      if (this.brand.serviceInfo && this.brand.serviceInfo.ccphones) {
        return this.brand.serviceInfo.ccphones;
      }
      return [];
    }
  },
  methods: {
    onCall() {
      if (this.brand.serviceInfo) {
        if (this.ccphones.length) {
          this.popupVisible = true;
        } else {
          this.callPhone(this.brand.serviceInfo.ccphone);
        }
      }
    },
    callPhone(phone) {
      window.location.href = `tel://${phone}`;
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  width: 25px;
  height: 25px;
}
.box {
  width: 260px;
  text-align: left;
  .bottom {
    border-top: 1px solid #E4E4E4;
    display: flex;
    .mint-button {
      height: 35px;
      background-color: white;
      color: #47B526;
      font-size: 13px;
      border-radius: 0px;
    }
  }
}
</style>
