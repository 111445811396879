<template>
  <mt-tabbar class="tabbar" v-model="selected" :fixed="true">
    <mt-tab-item id="loan">
      <img slot="icon" v-if="selected == 'loan'" src="../assets/tabbar_1_1.png">
      <img slot="icon" v-else src="../assets/tabbar_1_0.png">
      {{ $t('loan') }}
    </mt-tab-item>
    <mt-tab-item id="profile">
      <img slot="icon" v-if="selected == 'profile'" src="../assets/tabbar_2_1.png">
      <img slot="icon" v-else src="../assets/tabbar_2_0.png">
      {{ $t('profile') }}
    </mt-tab-item>
    <mt-tab-item id="account">
      <img slot="icon" v-if="selected == 'account'" src="../assets/tabbar_3_1.png">
      <img slot="icon" v-else src="../assets/tabbar_3_0.png">
      {{ $t('account') }}
    </mt-tab-item>
  </mt-tabbar>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'loan',
    }
  },
  data () {
    return {
      selected: 'loan',
    }
  },
  watch: {
    selected(val) {
      let path = this.$route.path;
      if (val == 'loan' && path != '/') {
        this.$router.push("/");
      } else if (val == 'profile' && path != '/profile') {
        this.$router.push('/profile')
      } else if (val == 'account' && path != '/mine') {
        this.$router.push("/mine");
      }
    }
  },
  created() {
    this.selected = this.value
  }
}
</script>

<style lang="scss" scoped>
.tabbar {
  
}
</style>