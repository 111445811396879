export default {
  welcome: 'Welcome',
  projectName: 'LoanNaira',
  login: '登录',
  register: '注册',
  phone: '手机号',
  mine: '我的',
  logOut: '退出登录',
  loan: 'Loan',
  profile: 'Profile',
  account: 'Account',
  loginTips1: '点击使用短信验证码快速登录',
  loginTips2: '点击使用密码快速登录',
  upTo: 'UP TO',
  loanInfo: 'Loan arrived so fast ! !',
  userRepayInfo: "我应该还多少钱?",
  userRenewalInfo: "上次我借了多少钱?",
  userBorrowInfo: "我能借多少钱?",
  firstName: '名字',
  firstNamePlaceholder: '请输入名字',
  middleName: '中间名(可选)',
  middleNamePlaceholder: '请输入中间名',
  lastName: '姓',
  lastNamePlaceholder: '请输入姓',
  backupPhone: '备用电话(可选)',
  backupPhonePlaceholder: '请输入备用电话',
  whatsApp: 'whatsApp',
  whatsAppPlaceholder: '请输入whatsApp',
  whatsAppError: 'whatsApp不能为空',
  facebook: 'Facebook(可选)',
  facebookPlaceholder: '请输入Facebook',
  viewMore: '查看更多信息',
  userRecomendation: '用户反馈',
  personalInfo: '个人信息',
  workInfo: '工作信息',
  jobauth: '工作认证',
  wontactInfo: '联系人信息',
  relationshipauth: '联系人认证',
  idcardInfo: '身份证信息',
  idcardauth: '身份认证',
  confirmOrder: '确认订单',
  bankCardInfo: '银行卡信息',
  birthday: '生日',
  birthdayError: '生日不能为空',
  birthdayPlaceholder: '请选择您的生日',
  email: '邮箱',
  emailPlaceholder: '请输入您的邮箱',
  emailError: '邮箱不能为空',
  gender: '性别',
  genderError: '请选择性别',
  repaymentDate: '还款日期',
  withdrawalAmount: '可提现金额',
  repayableAmount: '应还金额',
  loanDetail: '订单详情',
  copy: '复制',
  year: '年',
  month: '月',
  date: '日',
  copySuccess: '复制成功!',
  invitationAddress: '专属邀请地址',
  inviteFriendsStep: '简单三步获取奖励',
  inviteFriendsSteptips1: '邀请好友注册',
  inviteFriendsSteptips2: '好友贷款成功',
  inviteFriendsSteptips3: '领取现金券',
  activityRules: '活动规则',
  toBeExchanged: '待兑换',
  exchanged: '已兑换',
  expired: '已过期',
  validUntil: '有效期至',
  exchangeImmediately: '立即兑换',
  couponPopupTitle: '温馨提示',
  couponPopupTips: '确定立即兑换优惠券？',
  activityRulesList: [
    '1.本次活动新老用户均可参加。',
    '2.活动奖金有限，先到先得，派完为止。',
    '3.成功邀请一位好友你可获得奖励。(好友完成贷款后才可获得奖励)。',
    '4.奖励金额直接发放到您的账户中。',
    '5.可在“优惠券”中查看奖励收益。',
    '6.本活动最终解析权归本公司所有。',
  ],
  male: '男',
  female: '女',
  degree: '学历',
  degreePlaceholder: '请选择您的学历',
  degreeError: '学历不能为空',
  marriage: '婚否',
  marriagePlaceholder: '请选择婚姻状况',
  marriageError: '婚姻状况不能为空',
  religion: '宗教',
  religionPlaceholder: '请选择宗教',
  religionError: '宗教不能为空',
  addressSelect1: '省/市',
  addressSelect2: '区',
  addressPlaceholder: "请选择地址",
  companyAddressSelectPlaceholder: "请选择公司地址",
  confirmBankCardInfo: '确认银行卡信息',
  addressError: '居住地址不能为空',
  houseNo: '房屋号',
  houseNoPlaceholder: "请输入房屋号",
  houseNoError: '请输入房屋号',
  companyAddress: '公司地址',
  companyAddressPlaceholder: "请输入公司地址",
  companyAddressError: "公司地址不能为空",
  residentialAddress: '居住地址',
  salaryType: '工资类型',
  salaryTypePlaceholder: '请选择工资类型',
  salaryTypeError: '请选择工资类型',
  companyName: '公司名称',
  payDate: '发薪周期',
  payDatePlaceholder: '请选择发薪周期',
  payDateError: '请选择发薪周期',
  payDateTips: 'Example:25 if you’re paid on the 25th',
  companyNamePlaceholder: "请输入公司名称",
  companyNameError: "公司名称不能为空",
  averageMonthlyIncome: '平均月收入',
  averageMonthlyIncomePlaceholder: "请选择平均月收入",
  averageMonthlyIncomeError: '平均月收入不能为空',
  cashRedPacket: '现金红包',
  invitePrize: '邀请有奖',
  inviteFriendsImmediately: '立即邀请好友',
  noInviteFriends: '还没有邀请好友',
  myPromotionTips: '成为新用户时间:',
  workPhone: '工作电话(可选)',
  bankInfo: '银行卡',
  bankInfoTips: '提示：收款和还款可能会受到银行业务的影响',
  bankInfoPopupTips: '如果账户信息填写不正确，您将无法获得贷款。',
  workPhonePlaceholder: '请输入工作电话',
  workPhoneError: '工作电话不能为空',
  workPhoneWarning: '请输入正确的工作电话',
  relativeContact: '亲属联系人',
  otherContact: '其他联系人',
  relationship: '关系',
  relationshipPlaceholder: '请选择联系人关系',
  relationshipError: '关系不能为空',
  orderHistory: '订单历史',
  repaymentInfo: '还款信息',
  repayment: '还款',
  remainingAmountDue: "Remaining amount due",
  repaymentTips: "There is one unpaid bill.",
  repaymentMessage: "We suggest that you repay the loan immediately on this page, so as not to affect your next loan application.",
  repaymentMessage2: "We need a few minutes to process your loan repayment application，please wait patiently. If you cannot receive the verification code, please resend the verification code. Thanks for your cooperation.",
  inviteFriends: '邀请好友',
  discountCoupon: '优惠卷',
  myPromotion: '我的推广',
  phonePlaceholder: '请输入您的手机号',
  phoneWarning: '请输入正确的手机号',
  phoneError: '手机号不能为空',
  password: '密码',
  passwordPlaceholder: '请输入密码',
  passwordWarning: '密码格式错误',
  passwordError: '密码不能为空',
  forgetPassword: '忘记密码',
  validateCode: '验证码',
  smsCode: '验证码',
  youCanTry: '您也可以试试',
  getIvrCode: '获取语音验证码',
  confirmPassword: '确认密码',
  validateCodeWarning: '验证码格式错误',
  validateCodeError: '验证码不能为空',
  comfirmPasswordWarning: '两次密码输入不一致',
  comfirmPasswordError: '请输入密码',
  getSmsTips: '验证码可能需要时间较长，请耐心等待',
  identifyCardTips: 'Photo should be clear and complete',
  holdTheIdentifyCardTips: 'I.e Take a picture holding your card. Fully exposed NIK card information Avioid exposed ID overlaps width the NIK card clear.',
  employeeIdCardTips: 'Upload your Emploe ID card to get more borowing money and loan faster.',
  loanTips: "Maintaining a good repayment behavior will help you to increase your loan amount. 96% of users loan amount haveincreased subsequently.",
  orderConfirmation: "Order confirmation:",
  fee: 'Fee',
  amountPayable: "Amount payable",
  repaymentReliefReward: "Repayment relief reward",
  repaymentAmount: "Repayment loan amount",
  discountTitle: '优惠券',
  discountTips: '你有一张优惠券可以使用',
  overdue: '过期',
  useImmediately: '立即使用',
  stage: "Stage",
  free: 'Free',
  clickingAgreeOur: "By clicking Submit,you agree to our",
  loanAgreement: "Loan agreement",
  clickModifyBankCard: "Click here to modify bank card",
  modifyBankInfo: '修改银行卡',
  oldBankAccount: "Old Bank Account",
  newBankAccount: "New Bank Account",
  getCodeTitle: "Please typ the verification code sent to",
  codeInfo: "Cant't receive the verification code?You can also check your Mobile phone signal or try the following ways.",
  getIvrHint: "Receive code by call",
  getCodeHint: "Re-acquire verification code",
  deductionAuth: "ATM Bank Card",
  cardVerificationStatus: "Card Verification Status",
  deductionAuthTips: "Please verify your bank card to get more credit.In this process,system will deduct your 50 NR for verification.It will be return to your when repay loan.",
  notVerified: "NOT VERIFIED",
  verified: "VERIFIED",
  validateCard: "Validate Card",
  stepBack: "Step back",
  applicationRepayment: "This application for repayment",
  confirm: '确认',
  cancel: '取消',
  idcard: 'BVN',
  idcardPlaceholder: '请输入BVN',
  idcardError: 'BVN不能为空',
  confirmModification: '确认修改',
  modifyImmediately: '立即修改',
  modify: '修改',
  submit: '提交',
  verifyMarkTips: '姓名与银行账号不匹配',
  settleMsg1: '*已结清客户再次申请额度更高，审核更快！',
  overdueMsg1: '*您的借款已逾期，逾期借款将会影响您的信用记录,后续无法从其他机构贷款，请尽快还款！',
  waitTips1: '贷款信息(审批中)',
  waitTips2: '刷新界面查看贷款状态',
  supplementaryTips1: '申请失败',
  supplementaryTips2: '您的身份信息填写不正确',
  supplementaryTips3: '请在一个工作日内补充完整,否则将取消申请!',
  passedTips1: '申请通过(放款中)',
  toBeRepaid1: '待偿还',
  toBeRepaid2: '提前还款有助于增加你下一笔贷款的金额和期限。',
  overdue1: '逾期未付',
  overdue2: '为了避免罚息，我们建议您提前或按时付款。保持良好的声誉将使您有资格通过LoanNaira获得更高的贷款额度。',
  settled1: '已结清',
  settled2: '祝贺你有机会获得更高额度的贷款。去申请吧！',
  rejectedTips1: '申请失败',
  rejectedTips2: '本次申请没有通过',
  dueDate: '还款日',
  amountDue: '到期金额',
  loanDate: '借款日期',
  loanAmount: '借款金额',
  loanTerm: '借款期限',
  repaymentPeriod: '还款期限',
  applicationStatus: '申请状态',
  moneyMaxTips: '您目前的最高贷款额',
  daysMaxTips: '您当前的最长借款期',
  filingDate: '提交日期',
  paymentDate: '付款日期',
  serviceFee: "服务费",
  amountOfRepayment: "Amount of repayment",
  day: '天',
  waitMsg1: '您的订单您的借款申请正在审核中，工作人员将在1-2个工作日内与您联系，请保持电话畅通！',
  passedMsg1: '*您的借款申请已通过审核，正在放款，预计1-2个工作日会到账;',
  passedMsg2: '若未收到款请先与您银行卡所在银行进行核对，其他问题请联系客服',
  reapply: '重新申请',
  back: '返回',
  nextStep: '下一步',
  applyImmediately: '立即申请',
  clickApplyAgree: '点击申请即同意',
  serviceAgreement: '《服务协议》',
  telephone: '客服电话',
  supplementaryInformation: '补充资料',
  supplementaryImage: '补充证件',
  supplementaryBankCard: '补充银行卡',
  prompt: '提示',
  idcardExample: '证件照上传示例',
  identifyCard: 'Identify card(optional)',
  holdTheIdentifyCard: 'Hold the identify card(optional)',
  employeeIdCard: 'Employee ID card(optional)',
  voterCard: 'Voter card(optional)',
  driverLicense: 'Driver license(optional)',
  passport: 'Passport(optional)',
  taxCard: 'Tax card(optional)',
  name: '姓名',
  namePlaceholder: '请输入姓名',
  nameError: '姓名不能为空',
  bankAccountHolder: '银行卡持有人',
  atmBankCardNo: 'atm bank card no',
  atmBankCardNoPlaceholder: 'Please enter atm bank card no',
  atmBankCardNoError: 'Please enter atm bank card no',
  bankCardNumber: '银行卡号',
  bankCardNumberPlaceholder: '请输入银行卡号',
  bankCardNumberError: '银行卡号不能为空',
  bankAccountNumber: '银行账号',
  bankAccountNumberPlaceholder: '请输入银行账号',
  bankAccountNumberError: '银行账号不能为空',
  bank: '银行',
  bankPlaceholder: '请选择银行',
  bankError: '银行不能为空',
  bankCard: '银行卡',
  contractNo: '合同号',
  amount: '金额',
  amountPlaceholder: '请输入金额',
  notHaveLoan: "You do not have a loan currently,please get a loan first.",
  getMoneyNow: "Get money now",
  selectPhoneTips: "Please select a mobile phone number you have used",
  applicationDate: '申请日期',
  loanLimit: '还款期限',
  contractStatus: '合同状态',
  profileTips: 'We promise to protect your privacy information.',
  profileCreateInfo1: 'We are calculating your quota',
  profileCreateInfo2: 'Expected to be completed in 10 seconds,please be patient.',
  loanTotalAmt: '应还总额',
  realTotalAmt: '已还总额',
  noBankCard: '没有银行卡',
  addBankCard: '添加银行卡',
  deductionSequence: '扣款顺序',
  checkTheBill: '检查帐单',
  unbind: '解除绑定',
  deductionSequenceTips: '扣除将按以下顺序进行',
  longPressDrag: '长按拖动',
  warmTips: '温馨提示',
  unbindTips: '是否确实要立即解除绑定？',
  cardBills: 'Card bills',
  refund: 'Refund',
  status: 'Status',
  refundTips: 'Refund application is successful, refund may be delayed, please wait patiently',
  repaymentRecord: 'Repayment record',
  processing: 'Processing',
  success: 'Success',
  failure: 'failure',
  idcardExampleList: [
    '标准', '边框缺失', '对焦模糊', '复印件拍照'
  ],
  byPaymentCompany: 'By payment company',
  byBank: 'By bank',
  accountName: 'Account name',
  bankName: 'Bank Name',
  bankAccount: 'Bank account',
  repaymentRemark: 'Repayment remark',
}